import { Link } from 'gatsby'
import React from 'react'
import './style.scss'

const currYear = new Date().getFullYear()

const Footer = ({ author, title }) => (
  <footer class="site-footer">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <h6>About</h6>
          <p class="text-justify">
            Daily Deals is a Shopify App that helps you easily create bulk
            discounts and sales so you don't have to manually update your
            catalog everyday. We'll save you time on creating deals so you can
            focus on running and managing your business!
          </p>
        </div>
      </div>
      <hr />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-sm-6 col-xs-12">
          <p class="copyright-text">
            Copyright &copy; {currYear} All Rights Reserved by{' '}
            <a href="#">Rok Apps</a>.
          </p>
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12">
          <ul class="social-icons">
            {/* <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
            <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
            <li><a class="linkedin" href="#"><i class="fa fa-linkedin"></i></a></li> */}
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
