import React from 'react'
import { Link } from 'gatsby'
import Modal from 'components/modal'
import TextField from 'components/textfield'

export default class Navi extends React.Component {
  state = {
    showModal: false,
    shopifyDomainName: '',
  }

  render() {
    const { showModal } = this.state
    const { location, title } = this.props

    return [
      <nav class="navbar fixed-top navbar-expand-lg navbar-light">
        <Link className="text-center" to="/">
          <img
            width="180"
            src="/img/daily-deals.svg"
            style={{ marginBottom: 0 }}
          />
        </Link>
        <div
          className="navbar-toggler navbar-button-wrapper"
          id="navbarTogglerDemo01"
          style={{ width: 225 }}
        >
          <form className="form-inline">
            <button
              onClick={() => {
                this.setState({ showModal: true })
              }}
              className="btn login-button button-pulse"
              type="button"
            >
              Add To Shopify
            </button>
          </form>
        </div>
        <div class="navbar-collapse collapse">
          <ul class="navbar-nav ml-auto">
            <li
              className={
                location.pathname === '/' ? 'nav-item active' : 'nav-item'
              }
            >
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li
              className={
                location.pathname === '/Support/'
                  ? 'nav-item active'
                  : 'nav-item'
              }
            >
              <a
                href="https://help.dailydeals.ai/"
                className="nav-link"
                target="_blank"
              >
                Support
              </a>
            </li>
            <div className="navbar-button-wrapper" id="navbarTogglerDemo01">
              <form className="form-inline">
                <button
                  onClick={() => {
                    this.setState({ showModal: true })
                  }}
                  className="btn login-button button-pulse"
                  type="button"
                >
                  Add To Shopify
                </button>
              </form>
            </div>
          </ul>
        </div>
      </nav>,
      <Modal
        show={showModal}
        hideModal={() => this.setState({ showModal: false })}
      >
        <div className="signup-modal-header">
          <h2>Install on Shopify</h2>
        </div>
        <p>
          Connect Daily Deals to your Shopify store now and begin offering sales
          in 3 minutes.
        </p>
        <TextField
          id={1}
          label="Shopify Domain Name"
          locked={false}
          type={'text'}
          active={false}
          autoFocus={false}
          updateFieldValue={value => {
            this.setState({ shopifyDomainName: value })
          }}
        />
        <p className="helpText">Example: mystore.myshopify.com</p>
        <div className="install-btn-wrapper">
          <button
            onClick={() => {
              const { shopifyDomainName } = this.state
              const splitName = shopifyDomainName.split('.')
              if (
                splitName.length === 3 &&
                splitName[1] === 'myshopify' &&
                splitName[2] === 'com'
              ) {
                window.location.href = `https://shopify.dailydeals.ai/auth?shop=${shopifyDomainName}`
              } else {
                alert('Please enter a valid Shopify Domain Name.')
              }
            }}
            className="btn login-button"
          >
            Install App
          </button>
        </div>
      </Modal>,
    ]
  }
}
