import React from "react";

const Modal = props => {
    return (
        props.show ?
            [<div className="add-to-shopify-modal" id="modal">
                <div className="modal-guts">
                    {props.children}
                </div>
            </div>,
            <div
                onClick={() => {
                    document.getElementById('document').classList.remove("scroll-lock");
                    document.ontouchmove = function () {
                        return true;
                    }
                    props.hideModal();
                }}
                className="modal-overlay"
                id="modal-overlay"></div>
            ]
            : null
    );
};

export default Modal;
